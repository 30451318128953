
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'camera': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 17.016c2.766 0 5.016-2.25 5.016-5.016S14.766 6.984 12 6.984 6.984 9.234 6.984 12s2.25 5.016 5.016 5.016zm-3-15h6l1.828 1.969h3.188c1.078 0 1.969.938 1.969 2.016v12c0 1.078-.891 2.016-1.969 2.016H3.985c-1.078 0-1.969-.938-1.969-2.016v-12c0-1.078.891-2.016 1.969-2.016h3.188zM8.813 12c0-1.781 1.406-3.188 3.188-3.188s3.188 1.406 3.188 3.188-1.406 3.188-3.188 3.188S8.813 13.782 8.813 12z"/>'
  }
})
