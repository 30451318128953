
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'microscope': {
    width: 24,
    height: 32,
    viewBox: '0 0 24 32',
    data: '<path pid="0" d="M19.281 28C21.978 27.414 24 24.875 24 22c0-1.826-.832-3.441-2.121-4.543.074-.477.121-.961.121-1.457a9.931 9.931 0 0 0-4-8l2-2V4l2-2-2-2-2 2h-2l-8 8-4 2v4l2 2h4l2-4 3-3c1.734 1.044 3 2.826 3 5a6 6 0 0 0-6 6H0v2h6c.598.445 1.314.691 2 1v3H4l-4 4h24l-4-4h-.719zM16 22a2 2 0 1 1 3.999-.001A2 2 0 0 1 16 22z"/>'
  }
})
