
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'mac': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M25.385 17.004c.047 4.841 4.559 6.449 4.611 6.473-.041.109-.723 2.295-2.381 4.552-1.434 1.949-2.918 3.89-5.26 3.929-2.303.041-3.043-1.271-5.676-1.271-2.632 0-3.454 1.232-5.632 1.312-2.259.08-3.981-2.104-5.427-4.048C2.666 23.977.41 16.722 3.442 11.826a8.52 8.52 0 0 1 7.112-4.013c2.22-.037 4.317 1.394 5.672 1.394 1.359 0 3.906-1.719 6.582-1.467 1.121.043 4.268.42 6.287 3.169-.159.097-3.755 2.041-3.71 6.095zm-9.429-9.629c-.261-1.839.712-3.751 1.824-4.952C19.021 1.07 21.115.064 22.844 0c.221 1.874-.59 3.755-1.789 5.109-1.201 1.352-3.17 2.405-5.099 2.266z"/>'
  }
})
