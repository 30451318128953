
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'server': {
    width: 24,
    height: 32,
    viewBox: '0 0 24 32',
    data: '<path pid="0" d="M22 12H2c-1.094 0-2 1-2 2v4c0 1 1 2 2 2h20c1 0 2-1 2-2v-4c0-1-1-2-2-2zM4 18H2v-4h2v4zm4 0H6v-4h2v4zm4 0h-2v-4h2v4zm4 0h-2v-4h2v4zm6 4H2c-1.094 0-2 1-2 2v4c0 1 1 2 2 2h20c1 0 2-1 2-2v-4c0-1-1-2-2-2zM4 28H2v-4h2v4zm4 0H6v-4h2v4zm4 0h-2v-4h2v4zm4 0h-2v-4h2v4zm6-26H2C.906 2 0 3 0 4v4c0 1 1 2 2 2h20c1 0 2-1 2-2V4c0-1-1-2-2-2zM4 8H2V4h2v4zm4 0H6V4h2v4zm4 0h-2V4h2v4zm4 0h-2V4h2v4zm6-2h-2V4h2v2z"/>'
  }
})
