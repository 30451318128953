
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'delete-forever': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.297 2.203h4.24v2.464H3.464V2.203h4.24L8.964 1h6.073zm-8.651 9.625l2.635 2.635-2.578 2.578 1.719 1.719L12 16.182l2.578 2.578 1.719-1.719-2.578-2.578 2.578-2.635-1.719-1.719L12 12.744l-2.578-2.635zm-2.979 8.709V5.87h14.667v14.667c0 1.318-1.146 2.464-2.464 2.464H7.13c-1.318 0-2.464-1.146-2.464-2.464z"/>'
  }
})
