
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'credit-card': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M4 22h4v-2H4v2zm6 0h4v-2h-4v2zm2-6H4v2h8v-2zm-4-2h2l4-4h-2l-4 4zm6 4h6v-2h-6v2zM30 6H2C1 6 0 7 0 8v16c0 1 1 2 2 2h28c1 0 2-1 2-2V8c0-1-1-2-2-2zm0 8v9s0 1-1 1H3c-1 0-1-1-1-1v-9h2l4-4H2V9s0-1 1-1h26c1 0 1 1 1 1v1H18l-4 4h16z"/>'
  }
})
