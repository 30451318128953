
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'equalizerv': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 10.5h.375c.619 0 1.125-.506 1.125-1.125v-3.75c0-.619-.506-1.125-1.125-1.125H21V0h-3v4.5h-.375c-.619 0-1.125.506-1.125 1.125v3.75c0 .619.506 1.125 1.125 1.125H18V24h3V10.5zM18 6h3v3h-3V6zm-4.125 13.5c.619 0 1.125-.506 1.125-1.125v-3.75c0-.619-.506-1.125-1.125-1.125H13.5V0h-3v13.5h-.375C9.506 13.5 9 14.006 9 14.625v3.75c0 .619.506 1.125 1.125 1.125h.375V24h3v-4.5h.375zM10.5 15h3v3h-3v-3zm-4.125-4.5c.619 0 1.125-.506 1.125-1.125v-3.75c0-.619-.506-1.125-1.125-1.125H6V0H3v4.5h-.375c-.619 0-1.125.506-1.125 1.125v3.75c0 .619.506 1.125 1.125 1.125H3V24h3V10.5h.375zM3 6h3v3H3V6z"/>'
  }
})
