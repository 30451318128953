
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'load': {
    width: 24,
    height: 32,
    viewBox: '0 0 24 32',
    data: '<path pid="0" d="M18 2H0v28h24V8l-6-6zm4 26H2V4h14l6 6v18zM12 14c-4.656 0-8 3.469-8 10 1-4.25 4.094-6 8-6v4l8-6-8-6v4z"/>'
  }
})
