
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'move': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.488 4.441v6.047h6v-3L24 12l-4.512 4.511v-3.07h-6v6.047h3.023L12 23.999l-4.512-4.511h3v-6.047h-6v3.047L0 12l4.488-4.488v2.976h6V4.441H7.559L12.001 0l4.442 4.441h-2.954z"/>'
  }
})
