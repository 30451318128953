
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'floppy': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M30 32H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h24l6 6v24a2 2 0 0 1-2 2zM8 27a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v8zM22 5a1 1 0 1 0-2 0v3a1 1 0 1 0 2 0V5zm6 2l-3-3h-1v7a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V4H5a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h1V17a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v11h1a1 1 0 0 0 1-1V7z"/>'
  }
})
