
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pinned': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g stroke-width="1.146"><path pid="0" d="M14.756 6.283C9.17 6.283 4.64 10.867 4.64 16.54c0 5.672 4.529 10.256 10.116 10.256 4.911 0 9.005-3.544 9.923-8.255a7.869 7.869 0 0 1-5.218 1.972c-4.29 0-7.766-3.375-7.766-7.537.003-2.858 1.644-5.343 4.06-6.618l.042-.02c-.342-.036-.69-.054-1.04-.054zM8.944 7.404c-3.569 1.9-5.952 5.558-5.954 9.765 0 6.114 5.031 11.07 11.238 11.07 5.982-.007 10.867-4.616 11.206-10.426l.002-.03c-.01.012-.016.018-.022.023l.004-.004c-.62 5.4-5.154 9.603-10.661 9.603-5.931 0-10.738-4.873-10.738-10.867 0-3.828 1.963-7.198 4.925-9.134z"/><path pid="1" d="M20.921 3.76c-4.467 0-8.089 3.569-8.089 7.97 0 4.4 3.622 7.967 8.089 7.967 4.467 0 8.089-3.567 8.09-7.968 0-4.4-3.623-7.968-8.09-7.968zm.424 1.144H21.399c3.823.087 6.853 3.165 6.767 6.876a6.547 6.547 0 0 1-1.103 3.478l.017-.028a6.89 6.89 0 0 0 .568-2.582v-.01l.002-.162c0-3.867-3.137-7.013-7.04-7.104h-.01l-.153-.002c-1.1 0-2.14.242-3.073.675l.044-.018a7.016 7.016 0 0 1 3.922-1.124h.003z"/></g>'
  }
})
