
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'copy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.984 21V6.984H8.015V21h10.969zm0-15.984c1.078 0 2.016.891 2.016 1.969v14.016c0 1.078-.938 2.016-2.016 2.016H8.015c-1.078 0-2.016-.938-2.016-2.016V6.985c0-1.078.938-1.969 2.016-1.969h10.969zm-3-4.032V3h-12v14.016H2.015V3c0-1.078.891-2.016 1.969-2.016h12z"/>'
  }
})
