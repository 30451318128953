
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'stopwatch': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 6.038V4h4V2a2 2 0 0 0-2-2h-6a2 2 0 0 0-2 2v2h4v2.038C7.288 6.549 2 12.157 2 19c0 7.18 5.82 13 13 13s13-5.82 13-13c0-6.843-5.288-12.451-12-12.962zm6.071 20.033C20.182 27.96 17.671 29 15 29s-5.182-1.04-7.071-2.929C6.04 24.182 5 21.671 5 19s1.04-5.182 2.929-7.071a9.927 9.927 0 0 1 6.754-2.923l-.677 9.813c-.058.822.389 1.181.995 1.181s1.053-.36.995-1.181l-.677-9.813a9.933 9.933 0 0 1 6.754 2.923c1.889 1.889 2.929 4.4 2.929 7.071s-1.04 5.182-2.929 7.071z"/>'
  }
})
