
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cube': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: `
      <path d="M6.45586 0.160336C6.80586 0.0276094 7.19414 0.0276094 7.54414 0.160336L13.0129 2.23477C13.6062 2.46036 14 3.02965 14 3.66676V10.3332C14 10.9703 13.6062 11.539 13.0129 11.766L7.54414 13.8386C7.19414 13.948 6.80586 13.948 6.45586 13.8386L0.988203 11.766C0.393477 11.539 0 10.9703 0 10.3332V3.66676C0 3.02965 0.393477 2.46036 0.988203 2.23477L6.45586 0.160336ZM7.07656 1.38739C7.02734 1.36852 6.94805 1.36852 6.92344 1.38739L1.73961 3.35231L7 5.4195L12.2609 3.35231L7.07656 1.38739ZM1.45359 10.5382L6.34375 12.3922V6.5734L1.3125 4.59645V10.3332C1.3125 10.4234 1.36883 10.5054 1.45359 10.5382ZM7.65625 12.3922L12.5453 10.5382C12.6301 10.5054 12.6875 10.4234 12.6875 10.3332V4.59645L7.65625 6.5734V12.3922Z"/>
    `
  }
})
