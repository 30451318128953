
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'clowd-up': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 12l-6 6h4v10h4V18h4l-6-6zm10-2c-.273 0-.535.044-.801.08a10 10 0 0 0-18.399 0c-.264-.036-.525-.08-.8-.08a6 6 0 0 0 0 12c.642 0 1.246-.133 1.824-.326A6.96 6.96 0 0 0 12 23.891v-2.039c-1.558-.324-2.862-1.338-3.52-2.742a3.95 3.95 0 0 1-2.48.891c-2.208 0-4-1.793-4-4s1.792-4 4-4c.79 0 1.52.236 2.138.63.654-3.762 3.912-6.63 7.862-6.63 3.953 0 7.18 2.882 7.828 6.648A3.979 3.979 0 0 1 26 12.001c2.207 0 4 1.792 4 4s-1.793 4-4 4c-.32 0-.625-.047-.926-.117A4.984 4.984 0 0 1 21 22.001a4.46 4.46 0 0 1-1-.125v2.029c.326.053.658.096 1 .096a6.977 6.977 0 0 0 4.906-2.014c.031 0 .059.014.094.014a6 6 0 1 0 0-12z"/>'
  }
})
