export const SI = {
  'y': 1e-24,
  'z': 1e-21,
  'a': 1e-18,
  'f': 1e-15,
  'p': 1e-12,
  'n': 1e-9,
  'µ': 1e-6,
  'm': 1e-3,
  'k': 1e3,
  'M': 1e6,
  'G': 1e9,
  'T': 1e12,
  'P': 1e15,
  'E': 1e18,
  'Z': 1e21,
  'Y': 1e24
}

export const SInames = {
  'y': 'yocto',
  'z': 'zepto',
  'a': 'atto',
  'f': 'femto',
  'p': 'pico',
  'n': 'nano',
  'µ': 'micro',
  'm': 'milli',
  'k': 'kilo',
  'M': 'mega',
  'G': 'giga',
  'T': 'tera',
  'P': 'peta',
  'E': 'exa',
  'Z': 'zetta',
  'Y': 'yotta'
}
