<template>
  <div class="logo-content">
    <img src="@/assets/svg/logo.svg" alt="">
  </div>
</template>
<script>
import '../icons/rsk'
export default {
  name: 'logo'
}
</script>
