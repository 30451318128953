
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'git-merge': {
    width: 18,
    height: 24,
    viewBox: '0 0 18 24',
    data: '<path pid="0" d="M14.429 10.643c-1.01 0-1.879.558-2.346 1.376-.122-.009-.241-.019-.368-.019-2.779 0-5.417-2.117-6.379-4.738.579-.498.95-1.227.95-2.048a2.715 2.715 0 0 0-5.428 0c0 1.001.548 1.867 1.357 2.339v8.895a2.7 2.7 0 0 0-1.357 2.338 2.715 2.715 0 0 0 5.428 0 2.702 2.702 0 0 0-1.357-2.338v-4.883c1.785 1.888 4.249 3.15 6.786 3.15.135 0 .246-.009.368-.013a2.705 2.705 0 0 0 2.346 1.37c1.498 0 2.714-1.217 2.714-2.714s-1.217-2.714-2.714-2.714zm-10.858 9.5a1.357 1.357 0 1 1 0-2.714 1.357 1.357 0 0 1 0 2.714zm0-13.572a1.356 1.356 0 1 1 0-2.714c.751 0 1.357.606 1.357 1.357s-.606 1.357-1.357 1.357zm10.858 8.143a1.357 1.357 0 1 1 0-2.714 1.357 1.357 0 0 1 0 2.714z"/>'
  }
})
