
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'table': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.66675 15C1.66675 14.2234 1.66675 13.8352 1.79361 13.5288C1.96277 13.1205 2.28723 12.796 2.69561 12.6268C3.0019 12.5 3.39018 12.5 4.16675 12.5H15.8334C16.61 12.5 16.9982 12.5 17.3046 12.6268C17.7129 12.796 18.0374 13.1205 18.2066 13.5288C18.3334 13.8352 18.3334 14.2234 18.3334 15C18.3334 15.7766 18.3334 16.1648 18.2066 16.4712C18.0374 16.8795 17.7129 17.204 17.3046 17.3732C16.9982 17.5 16.61 17.5 15.8334 17.5H4.16675C3.39018 17.5 3.0019 17.5 2.69561 17.3732C2.28723 17.204 1.96277 16.8795 1.79361 16.4712C1.66675 16.1648 1.66675 15.7766 1.66675 15Z" stroke="white" stroke-width="1.5"/>
      <path d="M1.66675 10C1.66675 9.22342 1.66675 8.83517 1.79361 8.52883C1.96277 8.12048 2.28723 7.79602 2.69561 7.62687C3.0019 7.5 3.39018 7.5 4.16675 7.5H15.8334C16.61 7.5 16.9982 7.5 17.3046 7.62687C17.7129 7.79602 18.0374 8.12048 18.2066 8.52883C18.3334 8.83517 18.3334 9.22342 18.3334 10C18.3334 10.7766 18.3334 11.1648 18.2066 11.4712C18.0374 11.8795 17.7129 12.204 17.3046 12.3732C16.9982 12.5 16.61 12.5 15.8334 12.5H4.16675C3.39018 12.5 3.0019 12.5 2.69561 12.3732C2.28723 12.204 1.96277 11.8795 1.79361 11.4712C1.66675 11.1648 1.66675 10.7766 1.66675 10Z" stroke="white" stroke-width="1.5"/>
      <path d="M1.66675 5C1.66675 4.22343 1.66675 3.83515 1.79361 3.52886C1.96277 3.12048 2.28723 2.79603 2.69561 2.62687C3.0019 2.5 3.39018 2.5 4.16675 2.5H15.8334C16.61 2.5 16.9982 2.5 17.3046 2.62687C17.7129 2.79603 18.0374 3.12048 18.2066 3.52886C18.3334 3.83515 18.3334 4.22343 18.3334 5C18.3334 5.77657 18.3334 6.16485 18.2066 6.47114C18.0374 6.87952 17.7129 7.20398 17.3046 7.37313C16.9982 7.5 16.61 7.5 15.8334 7.5H4.16675C3.39018 7.5 3.0019 7.5 2.69561 7.37313C2.28723 7.20398 1.96277 6.87952 1.79361 6.47114C1.66675 6.16485 1.66675 5.77657 1.66675 5Z" stroke="white" stroke-width="1.5"/>
      <path d="M4.16659 10.8334C4.62682 10.8334 4.99992 10.4603 4.99992 10.0001C4.99992 9.53984 4.62682 9.16675 4.16659 9.16675C3.70635 9.16675 3.33325 9.53984 3.33325 10.0001C3.33325 10.4603 3.70635 10.8334 4.16659 10.8334Z" fill="white"/>
      <path d="M4.16659 5.83341C4.62682 5.83341 4.99992 5.46032 4.99992 5.00008C4.99992 4.53984 4.62682 4.16675 4.16659 4.16675C3.70635 4.16675 3.33325 4.53984 3.33325 5.00008C3.33325 5.46032 3.70635 5.83341 4.16659 5.83341Z" fill="white"/>
      <path d="M4.16659 15.8334C4.62682 15.8334 4.99992 15.4603 4.99992 15.0001C4.99992 14.5398 4.62682 14.1667 4.16659 14.1667C3.70635 14.1667 3.33325 14.5398 3.33325 15.0001C3.33325 15.4603 3.70635 15.8334 4.16659 15.8334Z" fill="white"/>
      </svg>
    `
  }
})
