
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'reload-alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.5 18c3.311 0 6-2.689 6-6s-2.689-6-6-6-6 2.69-6 6v3l3.015.017L6 19.526l-4.5-4.509h3V12c0-4.969 4.031-9 9-9s9 4.031 9 9-4.031 9-9 9v-3z"/>'
  }
})
