
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ellipsis': {
    width: 24,
    height: 32,
    viewBox: '0 0 24 32',
    data: '<path pid="0" d="M20 10H4c-2 0-4 2-4 4v4c0 2 2 4 4 4h16c2 0 4-2 4-4v-4c0-2-2-4-4-4zM8 18H4v-4h4v4zm6 0h-4v-4h4v4zm6 0h-4v-4h4v4z"/>'
  }
})
