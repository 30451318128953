
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'clowd-down': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M26 10c-.273 0-.535.044-.801.08C23.676 6.508 20.129 4 16 4s-7.674 2.508-9.2 6.08c-.264-.036-.525-.08-.8-.08a6 6 0 1 0 0 12c.642 0 1.246-.133 1.824-.324A6.969 6.969 0 0 0 12 23.891v-2.039c-1.558-.324-2.862-1.338-3.52-2.746a3.93 3.93 0 0 1-2.48.895c-2.208 0-4-1.793-4-4s1.792-4 4-4c.79 0 1.52.236 2.138.628.654-3.76 3.912-6.628 7.862-6.628 3.953 0 7.18 2.882 7.828 6.648A3.979 3.979 0 0 1 26 12.001c2.207 0 4 1.792 4 4s-1.793 4-4 4c-.32 0-.625-.047-.926-.117A4.984 4.984 0 0 1 21 22.001a4.46 4.46 0 0 1-1-.125v2.029c.326.053.658.096 1 .096 1.912 0 3.641-.77 4.904-2.014.033.002.061.014.096.014a6 6 0 1 0 0-12zm-8 6h-4v10h-4l6 6 6-6h-4V16z"/>'
  }
})
