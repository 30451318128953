
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'repo-forked': {
    width: 18,
    height: 24,
    viewBox: '0 0 18 24',
    data: '<path pid="0" d="M16.876 4.124c0-1.456-1.169-2.625-2.625-2.625s-2.625 1.169-2.625 2.625c0 .964.533 1.825 1.313 2.277v2.174l-3.938 4.348-3.938-4.348V6.401a2.621 2.621 0 0 0 1.313-2.277c0-1.456-1.169-2.625-2.625-2.625S1.126 2.668 1.126 4.124c0 .964.533 1.825 1.313 2.277v3.2l5.251 5.784v2.236a2.621 2.621 0 0 0-1.313 2.277c0 1.456 1.169 2.625 2.625 2.625s2.625-1.169 2.625-2.625c0-.964-.533-1.825-1.313-2.277v-2.236l5.251-5.784v-3.2a2.621 2.621 0 0 0 1.313-2.277zM3.749 2.832c.697 0 1.272.574 1.272 1.272s-.574 1.272-1.272 1.272-1.272-.574-1.272-1.272.574-1.272 1.272-1.272zM9 21.107c-.697 0-1.272-.574-1.272-1.272S8.302 18.563 9 18.563s1.272.574 1.272 1.272S9.698 21.107 9 21.107zm5.251-18.275c.697 0 1.272.574 1.272 1.272s-.574 1.272-1.272 1.272-1.272-.574-1.272-1.272.574-1.272 1.272-1.272z"/>'
  }
})
