
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'versions': {
    width: 28,
    height: 32,
    viewBox: '0 0 28 32',
    data: '<path pid="0" d="M0 22h4v-2H2v-8h2v-2H0v12zM12 6v20h16V6H12zm12 16h-8V10h8v12zM6 24h4v-2H8V10h2V8H6v16z"/>'
  }
})
