
/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'browser': {
    width: 28,
    height: 32,
    viewBox: '0 0 28 32',
    data: '<path pid="0" d="M10 6h2v2h-2V6zM6 6h2v2H6V6zM2 6h2v2H2V6zm24 20H2V10h24v16zm0-18H14V6h12v2zm2-2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V6z"/>'
  }
})
